import { Typography } from 'antd'

const NotFound = () => {
  return (
    <Typography style={{ padding: '20px', fontWeight: 'bold' }}>
      Not Found
    </Typography>
  )
}

export default NotFound
